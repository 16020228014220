<script>
import _ from 'lodash'

export default {
	lang: 'books',
	inject: ['editor'],
	data() {
		return {
			tabIndex: -1,
		}
	},
	computed: {
		e() {
			return this.editor()
		},
		tabs() {
			let data = this.e.customization.data
			let { $lang } = this
			return [
				{
					title: $lang('Nombre de tu peque'),
					get value() {
						return data.name
					},
					incompleteValue: $lang('Completa los datos'),
					get complete() {
						return data.name && data.gender && data.lang && data.color
					},
				},
				{
					title: data.nameAbuela
						? $lang('Nombre de su abuela')
						: $lang('Nombre de su abuela (opcional)'),
					get value() {
						return data.nameAbuela
					},
					incompleteValue: $lang('Completa los datos'),
					get complete() {
						return true
					},
				},
				{
					title: $lang('Dedicatoria'),
					get value() {
						return data.inscription
					},
					incompleteValue: $lang('Escribe una dedicatoria'),
					get complete() {
						return data.inscription
					},
				},
			]
		},
		dataChecker() {
			let data = this.e.customization.data
			let checker = _.pick(data, ['name', 'nameAbuela', 'gender', 'color', 'lang', 'inscription'])
			return JSON.stringify(checker)
		},
	},
}
</script>

<template>
	<BookEditor-Tabs :tabs="tabs" :data-checker="dataChecker" v-model="tabIndex">
		<template #item1>
			<div class="pr-md-6 w100">
				<BookEditorInput-TextField
					model-key="name"
					:label="$lang('Este libro personalizado es para')"
					:placeholder="$lang('Nombre de tu peque')"
				/>
				<div style="padding-top: 24px">
					<BookEditorInput-GenderCharacters />
				</div>
			</div>
			<div class="pt-4 pt-md-0 w100">
				<BookEditorInput-LangSelect />
				<BookEditorInput-ColorsSelector />
			</div>
		</template>
		<template #item2>
			<div class="d-flex d-md-block justify-center align-center">
				<BookEditorInput-TextField
					model-key="nameAbuela"
					:label="$lang('Nombre de su abuela (opcional)')"
					:placeholder="$lang('Nombre')"
				/>
			</div>
			<div
				style="max-width: 370px"
				class="ml-0 ml-md-12 mt-12 mt-md-0 primary--text text-center text-md-left"
			>
				{{
					$lang(`¡Las abuelas también pueden ser parte de esta aventura!
Si lo deseas, agrega el nombre de la abuela de tu peque para
que ella también isea protagonista de esta colorida aventura!
Si decides no incluir su nombre, aparecerá como Abue Lita.`)
				}}
			</div>
		</template>
		<template #item3>
			<BookEditorTab-Inscription />
		</template>
	</BookEditor-Tabs>
</template>
